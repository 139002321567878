@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");

body {
  font-family: "Montserrat", sans-serif !important;
  margin: 0 !important;
  overflow-x: hidden !important;
  color: #455a64 !important;
  font-weight: 300 !important;
  height: 100vh !important;
}

html {
  background: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
}

[class*="jss"] {
  z-index: 1 !important;
}

.z-button,
.z-button.disabled {
  margin-bottom: 5px !important;
  background: transparent;
  color: #5c4424 !important;
  /*-webkit-box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);*/
  /*box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);*/
  border: 1px solid #5c4424 !important;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  width: 100%;
  max-height: 50px !important;
}
.z-button:hover,
.z-button.disabled:hover {
  background: #ffb625 !important;
  color: #5c4424 !important;
  /*-webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);*/
  /*box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);*/
  border: 1px solid #5c4424;
}

.z-button.active,
.z-button:active,
.z-button:focus,
.z-button.disabled.active,
.z-button.disabled:active,
.z-button.disabled:focus {
  background: #cd8133 !important;
  color: #5c4424 !important;
  /*-webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);*/
  /*box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);*/
  border-color: transparent;
}

[class^="MUIDataTableHeadCell-fixedHeader-"] {
  top: 0;
  z-index: 0 !important;
  position: inherit !important;
  font-weight: bold !important;
  font-family: "Montserrat", sans-serif !important;
}

[class^="MUIDataTable-"] {
  padding: 2% !important;
  color: "red" !important;
  font-family: "Montserrat", sans-serif !important;
}

[class*="MUIDataTableHeadCell-toolButton"] {
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: "Montserrat", sans-serif !important;
}

.MuiTableCell-head {
  font-weight: bold !important;
  line-height: 1.5rem;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  white-space: nowrap !important;
  color: #5c4424 !important;
  background-color: white !important;
  font-family: "Montserrat", sans-serif !important;
}

.MuiTableRow-root {
  color: #5c4424 !important;
  display: table-row;
  outline: 0;
  vertical-align: middle;
  background-color: white !important;
  margin-bottom: 4px !important;
}

.custom-tag {
  text-align: center;
  min-width: 90px;
  max-height: 22px;
  overflow: hidden;
}

.tableTitle {
  margin-top: 3.6vh !important;
  display: flex;
  justify-content: flex-start;
  color: #5c4424 !important;
  cursor: default;
  font-size: 21px !important;
  font-weight: 400 !important;
}

.cardTitle {
  padding-top: 32px !important;
  margin-top: 3.6vh !important;
  margin-bottom: 5vh !important;
  display: flex;
  justify-content: center;
  color: #5c4424 !important;
  font-size: large;
  cursor: default;
}

.divider {
  font-weight: 800 !important;
  margin-bottom: 4vh !important;
  border: 1px #5c4424 solid !important;
}

@media only screen and (max-width: 575px) {
  .titleContainerResponsive {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.titleContainer {
  margin-top: 3.6vh !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.titleBack {
  display: flex;
  justify-content: center;
  color: #5c4424 !important;
  font-size: 21px !important;
  cursor: default;
  font-weight: 400 !important;
}

.back-button {
  width: "200%";
}

.subscriptionInfo {
  display: flex;
  justify-content: space-evenly;
}

.content {
  padding: 2% 10% 2% 10%;
  width: 100%;
  background-color: #fde6c0;
}

[class^="MUIDataTableHeadCell-"] {
  font-weight: bold !important;
  white-space: nowrap !important;
}

.headerContainer {
  height: 68px !important;
  position: fixed;
  width: 90%;
  z-index: 10;
}

.headerResponsive {
  height: 68px !important;
  display: flex !important;
  justify-content: space-between !important;
  position: fixed;
  width: 90%;
  z-index: 10;
}

.cell {
  display: flex;
  justify-content: space-between;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

html {
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 10px;
  height: 0px;
  background: transparent; /* make scrollbar transparent */
}

.MuiListItem-gutters {
  padding-left: 2px !important;
  padding-right: 16px;
}

.MuiButtonBase {
  height: 54px !important;
}
.MuiButtonBase:hover {
  height: 54px !important;
}

.MuiAppBar-colorPrimary {
  z-index: 1000 !important;
}

.MuiListItemIcon-root {
  min-width: 30px !important;
  margin-right: 2px !important;
}

.MuiFormLabel-root.Mui-disabled {
  color: rgba(84, 84, 84, 0.5) !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 1px solid rgba(0, 0, 0, 0);
  background-color: #fde6c0 !important;
  z-index: -1 !important;
  border-right: 4px solid #5c4424 !important;
}

.MuiDrawer-paperAnchorLeft {
  left: 0;
  right: auto;
  background-color: #fde6c0 !important;
  z-index: 0 !important;
  border-right: 4px solid #5c4424 !important;
}

.MuiDrawer-paperAnchorLeft {
  flex-shrink: 0;
  padding-left: 5px;
  padding-right: 5px;
  /*width: 240px;*/
  background-color: #fde6c0 !important;
}

.MuiTableCell-root {
  padding: 10px !important;
  color: #455a64 !important;
}

.MuiAppBar-colorPrimary {
  background-color: #5c4424 !important;
  color: #fde6c0 !important;
  box-shadow: none !important;
}

.MuiListItem-root {
  margin-bottom: 4px !important;
  margin-left: 4px !important;
  min-height: 54px !important;
}

.header {
  justify-content: space-between;
  min-height: 60px !important;
  max-height: 60px !important;
  z-index: 3000 !important;
  background-color: white;
}

.logo {
  height: 50px;
  text-align: center;
  font-weight: bold;
  color: #fafafa;
  font-size: 16pt;
  cursor: pointer;
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-container {
  margin-top: 6%;
  display: flex;
  justify-content: space-evenly;
}

::-webkit-calendar-picker-indicator {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(36deg) brightness(200%)
    contrast(0%);
}

.progress {
  margin-top: 8%;
}

.profileDescriptionContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.profileDescription {
  margin-top: 10vh;
  margin-bottom: 3%;
  width: 70%;
  padding: 1%;
}

@media only screen and (max-width: 575px) {
  .profileDescriptionResponsive {
    width: 95% !important;
  }
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  border: 0;
  margin-top: 5%;
  width: 100%;
}

.maintenance-container {
  display: flex;
  justify-content: center;
  padding-top: 5%;
  padding-bottom: 5%;
}

.image-responsive {
  width: 30%;
  height: auto;
  margin-top: 2%;
}

.image {
  width: 100%;
  height: auto;
  margin-top: 2%;
  margin-bottom: 2%;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 2%;
}

.login-box {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 50%;
  background-color: white;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1% 2% 1% 2%;
  border-radius: 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.login-box-responsive {
  margin-top: 10%;
  margin-bottom: 10%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80% !important;
  background-color: white;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1% 2% 1% 2%;
  border-radius: 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.login-form-forgot {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  text-decoration: none !important;
  color: #fde6c0;
}

.home-icon-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiPaper-elevation1 {
  box-shadow: 0 0 0 0 !important;
}

.card-logo {
  width: 80%;
}

.card-content {
  display: flex;
  justify-content: center;
}

.card-buttons {
  display: flex !important;
  justify-content: space-around !important;
  flex-wrap: wrap;
}

.details-container {
  display: flex;
  align-items: center;
}
